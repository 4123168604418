import { Component } from 'preact'
import Icon from 'Component/Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'

function getStatus (startTime, endTime) {
  const now = Date.now()
  const start = new Date(startTime).getTime()
  const end = new Date(endTime).getTime()

  if (now > end) {
    return 'COMPLETED'
  }
  if (now < start) {
    return 'UPCOMING'
  }
  return 'IN_PROGRESS'
}

export default class SplitListItem extends Component {
  handleClick = () => {
    const { onClick, id } = this.props

    if (onClick) {
      onClick(id)
    }
  }

  renderTimeline (status) {
    let iconSrc

    if (status === 'COMPLETED') iconSrc = 'circleCheck'
    else if (status === 'IN_PROGRESS') iconSrc = 'activeCircle'
    else iconSrc = 'eventCircle'

    const lineClasses = ['timelineLine']

    if (status === 'COMPLETED') {
      lineClasses.push('completed')
    }
    else if (status === 'IN_PROGRESS') {
      lineClasses.push('inProgress')
    }
    else {
      lineClasses.push('upcoming')
    }

    const iconClasses = ['timelineIcon']

    if (status === 'COMPLETED') {
      iconClasses.push('completed')
    }
    else if (status === 'IN_PROGRESS') {
      iconClasses.push('inProgress')
    }
    else {
      iconClasses.push('upcoming')
    }

    return (
      <div class="timelineContainer">
        <Icon name={ iconSrc } class={ iconClasses.join(' ') }/>
        <div class={ lineClasses.join(' ') }/>
      </div>
    )
  }

  render () {
    const {
      className = '',
      description,
      endTime,
      name,
      region,
      startTime,
      href,
      ...rest
    } = this.props
    const status = getStatus(startTime, endTime)

    const regionText = region
      ? locale.translate('seasons.split.regionLabel', { region })
      : ''
    const containerClass = ['SplitList', 'container', className].join(' ')

    let ActionTag = 'div'

    const actionProps = {}

    if (href) {
      ActionTag = 'a'
      actionProps.href = href
    }
    else if (this.props.onClick) {
      ActionTag = 'button'
      actionProps.type = 'button'
      actionProps.onClick = this.handleClick
    }

    return (
      <li class={ containerClass }>
        { this.renderTimeline(status) }

        <ActionTag class="summary group" { ...rest } { ...actionProps }>
          <div class="summaryRow">
            <p class="summaryTitle">{ name }</p>
            { !href && (
              <Icon
                name="linkArrowIcon"
                width={ 16 }
                height={ 16 }
                class="arrowIcon"
              />
            ) }
          </div>
          <div class="summaryRow">
            <p class="summaryLabel">{ regionText }</p>
          </div>
          { description && (
            <div class="summaryRow">
              <p class="summaryDescription">{ description }</p>
            </div>
          ) }
        </ActionTag>

        { this.props.children }
      </li>
    )
  }
}
