import { Component } from 'preact'
import EventsDesktopNavigation from './EventsDesktopNavigation'
import EventsMobileNavigation from './EventsMobileNavigation'

const riotbarOnShowCompleteEvent = 'riotbar_onshowcomplete'

export function getRiotBar () {
  const riotBarWindow = window

  return riotBarWindow.RiotBar
}

const DEVICES_SIZES = {
  xs: 0,
  sm: 640,
  md: 768,
  lg: 1024,
  xl: 1280,
  xxl: 1536
}

const SEASON_ID_REGEX = /^#(?<seasonId>\d+)$/

export default class EventsDropdown extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hasRiotBarRendered: false,
      desktopButtonRef: null,
      mobileButtonRef: null,
      seasonId: undefined
    }
    this.resizeObserver = null
  }

  componentDidMount () {
    this.checkForRiotBar()
    document.addEventListener(
      riotbarOnShowCompleteEvent,
      this.handleRiotBarEvent
    )
    if (window.ResizeObserver) {
      this.resizeObserver = new ResizeObserver(() => {
        this.handleResize()
      })
      this.resizeObserver.observe(document.body)
    }
    else {
      window.addEventListener('resize', this.handleResize)
    }
  }

  componentWillUnmount () {
    document.removeEventListener(
      riotbarOnShowCompleteEvent,
      this.handleRiotBarEvent
    )
    if (this.resizeObserver) {
      this.resizeObserver.disconnect()
    }
    else {
      window.removeEventListener('resize', this.handleResize)
    }
  }

  handleRiotBarEvent = () => {
    this.checkForRiotBar()
  }

  handleResize = () => {
    const { desktopButtonRef, mobileButtonRef } = this.state

    if (window.innerWidth > DEVICES_SIZES.lg) {
      if (!desktopButtonRef || !document.body.contains(desktopButtonRef)) {
        this.setupDesktopButtonRef()
      }
    }
    else {
      if (!mobileButtonRef || !document.body.contains(mobileButtonRef)) {
        this.setupMobileButtonRef()
      }
    }
  }

  checkForRiotBar () {
    const riotBar = getRiotBar()

    if (riotBar && riotBar.rendered) {
      this.setState({ hasRiotBarRendered: true }, () => {
        this.setupDesktopButtonRef()
        this.setupMobileButtonRef()
      })
    }
  }

  setupDesktopButtonRef () {
    const desktopButton = document.querySelector(
      '[data-testid="riotbar:desktopNav:link-internal-event-and-standings"]'
    )

    if (!desktopButton) return
    const desktopButtonHref = desktopButton.getAttribute('href') || ''
    const matched = desktopButtonHref.match(SEASON_ID_REGEX)
    const seasonId = matched && matched.groups ? matched.groups.seasonId : null

    if (!seasonId) return
    desktopButton.setAttribute('href', '')
    desktopButton.setAttribute('role', 'button')
    this.setState({ desktopButtonRef: desktopButton, seasonId })
  }

  setupMobileButtonRef () {
    const mobileButton = document.querySelector(
      '[data-testid="riotbar:mobile:link-event-and-standings"]'
    )

    if (!mobileButton) return
    const mobileHref = mobileButton.getAttribute('href') || ''
    const matched = mobileHref.match(SEASON_ID_REGEX)
    const seasonId = matched && matched.groups ? matched.groups.seasonId : null

    if (!seasonId) return
    mobileButton.setAttribute('href', '')
    mobileButton.setAttribute('role', 'button')
    this.setState({ mobileButtonRef: mobileButton, seasonId })
  }

  render (
    _,
    { hasRiotBarRendered, desktopButtonRef, mobileButtonRef, seasonId }
  ) {
    if (!hasRiotBarRendered) {
      return null
    }
    return (
      <div>
        { desktopButtonRef && (
          <EventsDesktopNavigation
            buttonRef={ desktopButtonRef }
            seasonId={ seasonId }
          />
        ) }
        { mobileButtonRef && (
          <EventsMobileNavigation
            buttonRef={ mobileButtonRef }
            seasonId={ seasonId }
          />
        ) }
      </div>
    )
  }
}
