import { Component } from 'preact'
import relApi from 'Common/Service/RelApi/RelApi'
import SplitListItem from './SplitListItem'
import SplitDialogListItem from './SplitDialogListItem'
import Icon from '../Asset/Icon/Icon'
import locale from 'Common/Locale/Locale'

export default class EventsMobileNavigation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      data: null,
      splitId: undefined
    }
    this.dialogRef = null
  }

  componentDidMount () {
    const { buttonRef, seasonId } = this.props

    // Listen to the mobile button
    if (buttonRef) {
      buttonRef.addEventListener('click', this.handleButtonClick)
    }
    // Fetch the data
    if (seasonId) {
      relApi.fetchSeason(seasonId).then((response) => {
        const seasonData = response || null

        this.setState({ data: seasonData })
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    // When modal open state changes, update the body scrolling
    if (prevState.isOpen !== this.state.isOpen) {
      if (this.state.isOpen) {
        document.body.style.overflow = 'hidden'
      }
      else {
        document.body.style.overflow = ''
      }
    }
  }

  componentWillUnmount () {
    const { buttonRef } = this.props

    if (buttonRef) {
      buttonRef.removeEventListener('click', this.handleButtonClick)
    }
    // Ensure scrolling is restored if the component unmounts
    document.body.style.overflow = ''
  }

  handleButtonClick = (e) => {
    e.preventDefault()
    e.stopPropagation()
    this.setState({ isOpen: true })
  }

  closeDialog = () => {
    this.setState({ isOpen: false, splitId: undefined }, () => {
      // Return focus to button if desired
      if (this.props.buttonRef) {
        this.props.buttonRef.focus()
      }
    })
  }

  goBack = () => {
    // If we are in a sub-split, close that sub-split
    if (this.state.splitId) {
      this.setState({ splitId: undefined })
      return
    }
    // Else close entire nav
    this.closeDialog()
  }

  closeNav = () => {
    const closeButton = document.querySelector('.mobile-menu-close')

    if (closeButton) {
      closeButton.click()
    }
    this.closeDialog()
  }

  handleBackdropClick = (e) => {
    if (!this.dialogRef) return
    // If they clicked outside the dialog, close
    if (!this.dialogRef.contains(e.target)) {
      this.goBack()
    }
  }

  render (_, { isOpen, data, splitId }) {
    let sortedSplits = []

    if (data && data.splits) {
      sortedSplits = [...data.splits].sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      )
    }

    const backdropClass = isOpen ? 'isDialogOpen' : ''
    const handbookUrl = `/season/${this.props.seasonId}/handbook`

    return (
      <div
        class={ `EventDropdownMobile backdrop ${backdropClass}` }
        onClick={ this.handleBackdropClick }>
        <div
          class="dialog"
          ref={ (el) => {
            this.dialogRef = el
          } }
          role="dialog"
          aria-modal="true">
          <header class="dialogHeader">
            <button
              class="goBackButton"
              onClick={ this.closeDialog }
              type="button">
              <Icon name="linkArrowIcon" class="goBackIcon"/>
            </button>
            <h2 class="dialogTitle">
              { this.props.buttonRef && this.props.buttonRef.textContent }
            </h2>
            <button class="closeButton" onClick={ this.closeNav } type="button">
              <Icon name="eventDropdownClose" class="closeIcon"/>
            </button>
          </header>
          <ol>
            { sortedSplits.map((split) => {
              if (split.tournaments && split.tournaments.length > 1) {
                return (
                  <SplitDialogListItem
                    key={ split.id }
                    split={ split }
                    isOpen={ splitId === split.id }
                    onOpen={ (id) => this.setState({ splitId: id }) }
                    onClose={ this.closeNav }
                    onGoBack={ this.goBack }
                    seasonId={ this.props.seasonId }
                    className="split"
                  />
                )
              }
              // Single tournament:
              const t = split.tournaments?.[0]
              const href = t ? `/tournament/${t.id}` : undefined

              return (
                <SplitListItem
                  key={ split.id }
                  id={ split.id }
                  name={ split.name || split.slug }
                  region={ split.region }
                  startTime={ split.startTime }
                  endTime={ split.endTime }
                  href={ href }
                  onClick={ this.closeNav }
                  isDateVisible={ false }
                  className="split"
                />
              )
            }) }
          </ol>
          <div class="aboutLeagueContainer">
            <header>
              <h3 class="aboutLeagueContainerTitle">Learn More</h3>
            </header>
            <a href={ handbookUrl } class="link" onClick={ this.closeNav }>
              { locale.translate('seasons.handbook.overview.title') }
            </a>
          </div>
        </div>
      </div>
    )
  }
}
