import { Component } from 'preact'
import SplitListItem from './SplitListItem'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'

export default class SplitDialogListItem extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.tournamentRefs = new Map()
    this.dialogRef = null
  }

  handleGoBackBtnClick = () => {
    this.props.onGoBack()
  }

  render () {
    const {
      split,
      isOpen,
      onClose,
      onOpen,
      className,
      seasonId,
      locale
    } = this.props
    const dialogClasses = isOpen
      ? 'SplitListDialog dialog isOpen'
      : 'SplitListDialog dialog'

    // Group tournaments by league
    const tournamentsByLeague = {}

    if (split.tournaments && split.tournaments.length > 0) {
      split.tournaments.forEach((tournament) => {
        const leagueId = tournament.league.id

        if (!tournamentsByLeague[leagueId]) {
          tournamentsByLeague[leagueId] = []
        }
        tournamentsByLeague[leagueId].push(tournament)
      })
    }

    // Create groups sorted by league display priority.
    const tournamentGroups = Object.keys(tournamentsByLeague)
      .map((key) => tournamentsByLeague[key])
      .sort((groupA, groupB) => {
        const posA = groupA[0].league.displayPriority
          ? groupA[0].league.displayPriority.position || 0
          : 0
        const posB = groupB[0].league.displayPriority
          ? groupB[0].league.displayPriority.position || 0
          : 0

        return posA - posB
      })

    return (
      <SplitListItem
        id={ split.id }
        name={ split.name || split.slug }
        region={ split.region }
        startTime={ split.startTime }
        endTime={ split.endTime }
        isDateVisible={ false }
        className={ className }
        onClick={ (id) => onOpen(id) }
        aria-controls={ `mobileSplitDialog-${split.id}` }
        aria-expanded={ isOpen }>
        <div
          class={ dialogClasses }
          id={ `mobileSplitDialog-${split.id}` }
          role="dialog"
          ref={ (el) => {
            this.dialogRef = el
          } }>
          <header class="dialogHeader">
            <button
              class="goBackButton"
              onClick={ this.handleGoBackBtnClick }
              type="button">
              <Icon name="linkArrowIcon" class="goBackIcon"/>
            </button>
            <h3 class="dialogTitle">{ split.name || split.slug }</h3>
            <button class="closeButton" onClick={ onClose } type="button">
              <Icon name="eventDropdownClose" class="closeIcon"/>
            </button>
          </header>
          { tournamentGroups.map((group) => {
            const tournament = group[0]

            let href = ''

            if (group.length === 1) {
              href = `/${locale ? locale + '/' : ''}tournament/${tournament.id}`
            }
            else {
              href = `/${locale ? locale + '/' : ''}season/${seasonId}/split/${
                split.id
              }/league/${tournament.league.id}`
            }
            return (
              <a
                className="dialogueTournamentLink"
                href={ href }
                onClick={ () => onClose() }>
                { tournament.league.image ? (
                  <Image
                    src={ tournament.league.image }
                    alt={ tournament.league.name }
                    class="tournamentImage"
                    width={ 24 }
                    height={ 24 }
                    size="24x24"
                  />
                ) : (
                  <Icon
                    name="circleDashOutline"
                    width={ 24 }
                    height={ 24 }
                    class="tournamentFallbackIcon"
                  />
                ) }
                <span>{ tournament.league.name }</span>
              </a>
            )
          }) }
        </div>
      </SplitListItem>
    )
  }
}
