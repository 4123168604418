import { Component } from 'preact'
import SplitListItem from './SplitListItem'
import Image from 'Component/Asset/Image/Image'
import Icon from 'Component/Asset/Icon/Icon'

export default class SplitDisclosureListItem extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.tournamentRefs = new Map()
  }

  handleClick = () => {
    const { isOpen, onOpen, onClose, split } = this.props

    if (!isOpen) {
      onOpen(split.id)
    }
    else {
      onClose(false)
    }
  }

  handleLinkClick = () => {
    // close entire navigation if we are navigating:
    this.props.onClose(true)
  }

  render () {
    const { split, isOpen, className, seasonId, locale } = this.props
    const classes = isOpen
      ? 'SplitDisclosure disclosure isOpen'
      : 'SplitDisclosure disclosure'

    // Group tournaments by league
    const tournamentsByLeague = {}

    if (split.tournaments && split.tournaments.length > 0) {
      split.tournaments.forEach((tournament) => {
        const leagueId = tournament.league.id

        if (!tournamentsByLeague[leagueId]) {
          tournamentsByLeague[leagueId] = []
        }
        tournamentsByLeague[leagueId].push(tournament)
      })
    }

    // Create groups sorted by league display priority.
    const tournamentGroups = Object.keys(tournamentsByLeague)
      .map((key) => tournamentsByLeague[key])
      .sort((groupA, groupB) => {
        const posA = groupA[0].league.displayPriority
          ? groupA[0].league.displayPriority.position || 0
          : 0
        const posB = groupB[0].league.displayPriority
          ? groupB[0].league.displayPriority.position || 0
          : 0

        return posA - posB
      })

    return (
      <SplitListItem
        id={ split.id }
        name={ split.name || split.slug }
        region={ split.region }
        startTime={ split.startTime }
        endTime={ split.endTime }
        isDateVisible={ false }
        className={ className }
        onClick={ this.handleClick }
        aria-controls={ `splitDisclosure-${split.id}` }
        aria-expanded={ isOpen }>
        <div class={ classes } id={ `splitDisclosure-${split.id}` }>
          { tournamentGroups.map((group) => {
            const tournament = group[0]

            let href = ''

            if (group.length === 1) {
              href = `/${locale ? locale + '/' : ''}tournament/${tournament.id}`
            }
            else {
              href = `/${locale ? locale + '/' : ''}season/${seasonId}/split/${
                split.id
              }/league/${tournament.league.id}`
            }
            return (
              <a
                class="tournamentLink"
                href={ href }
                onClick={ this.handleLinkClick }>
                { tournament.league.image ? (
                  <Image
                    src={ tournament.league.image }
                    alt={ tournament.league.name }
                    class="tournamentImage"
                    size="24x24"
                    width="24"
                    height="24"
                  />
                ) : (
                  <Icon
                    name="circleDash"
                    width={ 24 }
                    height={ 24 }
                    class="tournamentFallbackIcon"
                  />
                ) }
                { tournament.league.name }
              </a>
            )
          }) }
        </div>
      </SplitListItem>
    )
  }
}
