import { Component, render } from 'preact'
import relApi from 'Common/Service/RelApi/RelApi'
import SplitDisclosureListItem from './SplitDisclosureListItem'
import SplitListItem from './SplitListItem'
import locale from 'Common/Locale/Locale'
import Icon from 'Component/Asset/Icon/Icon'

export default class EventsDesktopNavigation extends Component {
  constructor (props) {
    super(props)
    this.state = {
      isOpen: false,
      data: null,
      splitId: undefined
    }
    this.disclosureRef = null
    this.currentButtonRef = null
  }

  componentDidMount () {
    this.bindButton(this.props.buttonRef)
    this.renderCaretIcon(this.props.buttonRef)

    const { seasonId } = this.props

    if (seasonId) {
      relApi.fetchSeason(seasonId).then((response) => {
        const seasonData = response || null

        this.setState({ data: seasonData })
      })
    }
  }

  componentDidUpdate (prevProps, prevState) {
    if (prevProps.buttonRef !== this.props.buttonRef) {
      if (this.currentButtonRef) {
        this.currentButtonRef.removeEventListener(
          'click',
          this.handleButtonClick
        )
      }
      this.bindButton(this.props.buttonRef)
      this.renderCaretIcon(this.props.buttonRef)
    }

    if (!prevState.isOpen && this.state.isOpen) {
      document.body.addEventListener('click', this.handleOutsideClick)
      document.body.addEventListener('keydown', this.handleKeyDown)
    }
    if (prevState.isOpen && !this.state.isOpen) {
      document.body.removeEventListener('click', this.handleOutsideClick)
      document.body.removeEventListener('keydown', this.handleKeyDown)
    }
  }

  componentWillUnmount () {
    if (this.currentButtonRef) {
      this.currentButtonRef.removeEventListener('click', this.handleButtonClick)
    }
    document.body.removeEventListener('click', this.handleOutsideClick)
    document.body.removeEventListener('keydown', this.handleKeyDown)
  }

  bindButton = (buttonRef) => {
    if (buttonRef && typeof buttonRef.addEventListener === 'function') {
      buttonRef.addEventListener('click', this.handleButtonClick)
      buttonRef.setAttribute('aria-controls', 'desktopEventsDisclosure')
      buttonRef.setAttribute('aria-expanded', 'false')
      this.currentButtonRef = buttonRef
    }
  }

  renderCaretIcon = (buttonRef) => {
    if (buttonRef) {
      const target = buttonRef.firstChild || buttonRef

      render(
        <div className="iconCaret">
          <Icon name="caret"/>
        </div>,
        target
      )
    }
  }

  handleButtonClick = (event) => {
    event.preventDefault()
    event.stopPropagation()
    this.setState(
      (prev) => ({ isOpen: !prev.isOpen }),
      () => {
        if (this.currentButtonRef) {
          this.currentButtonRef.setAttribute(
            'aria-expanded',
            String(this.state.isOpen)
          )
        }
      }
    )
  }

  handleOutsideClick = (event) => {
    const { buttonRef } = this.props

    if (buttonRef && buttonRef.contains(event.target)) return
    if (this.disclosureRef && this.disclosureRef.contains(event.target)) return
    this.closeDisclosure()
  }

  handleKeyDown = (event) => {
    if (event.key !== 'Escape') return
    this.closeDisclosure()
    if (
      this.currentButtonRef
      && typeof this.currentButtonRef.focus === 'function'
    ) {
      this.currentButtonRef.focus()
    }
  }

  closeDisclosure = () => {
    this.setState({ isOpen: false, splitId: undefined }, () => {
      if (this.currentButtonRef) {
        this.currentButtonRef.setAttribute('aria-expanded', 'false')
      }
    })
  }

  closeSplitDisclosure = (isNavigating) => {
    if (isNavigating) {
      this.closeDisclosure()
    }
    else {
      this.setState({ splitId: undefined })
    }
  }

  onGlobalTournamentClick = () => {
    this.closeSplitDisclosure(true)
  }

  render (_, { isOpen, data, splitId }) {
    let leftPos = 0

    if (this.props.buttonRef) {
      leftPos = this.props.buttonRef.getBoundingClientRect().left
    }
    let sortedSplits = []

    if (data && data.splits) {
      sortedSplits = [...data.splits].sort(
        (a, b) =>
          new Date(a.startTime).getTime() - new Date(b.startTime).getTime()
      )
    }
    const handbookUrl = `/season/${this.props.seasonId}/handbook`

    return (
      <div class="EventDropdown">
        <div
          id="desktopEventsDisclosure"
          ref={ (el) => {
            this.disclosureRef = el
          } }
          class={ `disclosure ${isOpen ? 'isOpen' : ''}` }
          style={ { left: leftPos } }>
          <ol>
            { sortedSplits.map((split) => {
              if (split.tournaments && split.tournaments.length > 1) {
                return (
                  <SplitDisclosureListItem
                    key={ split.id }
                    split={ split }
                    isOpen={ splitId === split.id }
                    onOpen={ (id) => this.setState({ splitId: id }) }
                    onClose={ this.closeSplitDisclosure }
                    seasonId={ this.props.seasonId }
                    className="split"
                  />
                )
              }
              const t = split.tournaments?.[0]
              const href = t ? `/tournament/${t.id}` : undefined

              return (
                <SplitListItem
                  key={ split.id }
                  id={ split.id }
                  name={ split.name || split.slug }
                  region={ split.region }
                  startTime={ split.startTime }
                  endTime={ split.endTime }
                  isDateVisible={ false }
                  className="split"
                  href={ href }
                  onClick={ this.onGlobalTournamentClick }
                />
              )
            }) }
          </ol>
          <div class="aboutLeagueContainer">
            <header>
              <h3 class="aboutLeagueContainerTitle">
                { locale.translate('seasons.learnMore') }
              </h3>
            </header>
            <ul>
              <li>
                <a
                  href={ handbookUrl }
                  class="link"
                  onClick={ this.closeDisclosure }>
                  { locale.translate('seasons.handbook.overview.title') }
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    )
  }
}
